import { Product } from 'interfaces';
import { ProductActionTypes } from './actionTypes';
import * as actions from './actions';

const initialState: Product.State = {
  list: [],
  loadingGet: false,
  error: false,
  product_banner: []
};

const setProduct = (state: Product.State, action: ReturnType<typeof actions.getProductSuccess>) => ({
  ...state,
  list: action.payload.data,
  loadingGet: false,
});

const setPending = (state: Product.State, action: ReturnType<typeof actions.getProductPending>) => ({
  ...state,
  loadingGet: true,
});

const setReject = (state: Product.State, action: ReturnType<typeof actions.getProductReject>) => ({
  ...state,
  loadingGet: false,
  error: true,
});

const setProductByBanner = (state: Product.State, action: ReturnType<typeof actions.onGetProductByBannerSuccess>) => {
  return {
    ...state,
    product_banner: action.payload.data.products,
    loadingGet: false,
  }
};

const setSearchProduct = (state: Product.State, action: ReturnType<typeof actions.onGetProductByBannerSuccess>) => {
  // console.log(state.list)
  return {
    ...state,
    list: state.list.filter((res: any) => {
      const data = res.product.name.toLowerCase().indexOf(action.payload) !== -1
      return data
    })
  }
}

const productReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case ProductActionTypes.GET_PRODUCT_LIST_SUCCESS:
      return setProduct(state, action);
    case ProductActionTypes.GET_PRODUCT_LIST_BANNER_SUCCESS:
      return setProductByBanner(state, action);
    case ProductActionTypes.GET_PRODUCT_LIST_PENDING:
      return setPending(state, action);
    case ProductActionTypes.GET_PRODUCT_LIST_REJECT:
      return setReject(state, action);
    case ProductActionTypes.SEARCH_PRODUCT_SUCCESS:
      return setSearchProduct(state, action);
    default:
      return state;
  }
};

export default productReducer;