import { useState, useEffect } from "react";
const { innerWidth } = window;

const useInfiniteScroll = () => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
  }, [isFetching]);

  function handleScroll() {
    if (innerWidth > 480) {
      if (
        document.documentElement.scrollTop >
          document.documentElement.offsetHeight &&
        !isFetching
      ) {
        setIsFetching(true);
      } else {
        setIsFetching(false);
      }
    } else {
      if (document.documentElement.scrollTop > 300 && !isFetching) {
        setIsFetching(true);
      } else {
        setIsFetching(false);
      }
    }
  }

  return [isFetching, setIsFetching] as const;
};

export default useInfiniteScroll;
