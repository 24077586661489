import { Common, Product, Banners } from 'interfaces';
import * as productServices from 'services/product';
import { ProductActionTypes } from './actionTypes';
import * as BannerServices from 'services/banners/banners'

export const getProductReject = () => ({
  type: ProductActionTypes.GET_PRODUCT_LIST_REJECT,
});

export const getProductPending = () => ({
  type: ProductActionTypes.GET_PRODUCT_LIST_PENDING,
});

export const getProductSuccess = (payload: { data: Product.Product[], pagination: Common.Pagination; }) => ({
  type: ProductActionTypes.GET_PRODUCT_LIST_SUCCESS,
  payload,
});

export const onGetProduct = (params: any) => (dispatch: (arg0: any) => void) => {
  return new Promise<void>(async (resolve, reject) => {
    dispatch(getProductPending())
    const result: Common.ApiResponseData<Product.Product[]> = await productServices.getProduct(params);

    const { code, data, code_message, code_type, pagination } = result;
    if (code === 200) {
      dispatch(getProductSuccess({ data, pagination }));
      resolve();
    } else {
      dispatch(getProductReject())
      reject(code_message);
    }
  })
};

export const onGetProductByBannerSuccess = (payload: { data: any }) => ({
  type: ProductActionTypes.GET_PRODUCT_LIST_BANNER_SUCCESS,
  payload,
})

export const onGetProductByBanner = (params: Common.ApiParams) => (dispatch: (arg0: any) => void) => {
  return new Promise<void>(async (resolve, reject) => {
    dispatch(getProductPending())
    const result: any = await BannerServices.getBannerDetail(params);
    const { code, data, code_message } = result
    if (code === 200) {
      dispatch(onGetProductByBannerSuccess({ data }))
      resolve()
    } else {
      reject(code_message)
    }
  })
}
export const searchProductSuccess = (payload: any) => ({
  type: ProductActionTypes.SEARCH_PRODUCT_SUCCESS,
  payload,
});

export const onSearchProduct = (payload: any) => (dispatch: (arg0: any) => void) => {
  return dispatch(searchProductSuccess(payload))
}


