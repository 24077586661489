import { State } from "./banners"
import { BannerDetail } from "./banner-detail"

export interface BannerIntialState {
    [x: string]: any
    banners: State
    banner_detail: BannerDetail
}

export const initialState: BannerIntialState = {
    banners: {
        current_page: 0,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        next_page_url: null,
        path: "",
        per_page: 0,
        prev_page_url: null,
        to: 0,
        total: 0,
        loading: false,
    },
    banner_detail: {
        id: 0,
        name_banner: "",
        start_date: "",
        expiry_date: "",
        links: "",
        syarat_ketentuan: "",
        status: 0,
        detail: null,
        voucher_id: 0,
        user_id: 0,
        approved_id: 0,
        status_oncheck: 0,
        type: 0,
        product_type: 0,
        coupon_code: "",
        coupon_category: "",
        coupon_amount: 0,
        coupon_min_purchase: 0,
        coupon_product: "",
        images_dashboard_mobile_url_original: "",
        images_page_mobile_url_original: "",
        images_page_website_url_original: "",
        products: [],
        new_products: {},
        loading: false
    }
}