import React from "react";
import { Carousel } from "antd";
import { Container, AbsoluteStyling } from "./style";
import { Image, Button, Text, Shimmer } from "components";
import { DataEntity } from "interfaces/banners/banners";
import { openApp } from "helpers";

interface CarouselProps {
  data?: DataEntity[] | null | undefined;
  getBannerDetail: (params: string) => void;
  getProduct: (params: any) => void;
  loading?: boolean;
  bannerId: number;
  type: number;
  branchId: string;
}
const Index = ({
  data,
  getBannerDetail,
  getProduct,
  loading,
  bannerId,
  type,
  branchId
}: CarouselProps) => {
  const [activeSlide, setActiveSlide] = React.useState<number>(0);
  const [nextSlide, setNextSlide] = React.useState<number>(0);
  const [centerMode, setCenterMode] = React.useState<boolean>(false)
  const sliderRef = React.useRef<any>(null);
  const initShimmer = [1, 2, 3];


  React.useEffect(() => {
    if (bannerId === 0 && activeSlide === 0) {
      changeBanner();
    }
    if (data?.length && data?.length > 1){
      setCenterMode(true)
    } else {
      setCenterMode(false)
    }
  }, [data]);

  React.useEffect(() => {
    changeBanner();
  }, [activeSlide]);

  const afteChange = (e: number) => {
    setNextSlide(e);
  };

  const beforeChange = (current: number, next: number) => {
    if (!loading && data?.length !== undefined) {
      setActiveSlide(next);
    }
  };

  const changeBanner = () => {
    if (data?.length !== undefined) {
      if (data[activeSlide] === undefined && bannerId !== 0) {
        getBannerDetail(bannerId.toString());
        getProduct({ banner_id: bannerId, type: type });
      } else if (data[activeSlide]) {
        getBannerDetail(data[activeSlide].id.toString());
        getProduct({
          banner_id: data[activeSlide].id,
          type: data[activeSlide].type,
        });
      }
    }
  };

  const redirectToApp = (id: number) => {
    openApp("1", id, branchId);
  };

  const slickMove = (type: string) => {
    if (sliderRef.current) {
      if (type === "next") {
        sliderRef.current?.next();
      } else {
        sliderRef.current?.prev();
      }
    }
  };
  return (
    <Container>
      <Carousel
        ref={sliderRef}
        // autoplay
        initialSlide={
          data?.length !== undefined && data.length > 0
            ? data.findIndex((x) => x.id === bannerId) === -1
              ? 0
              : data.findIndex((x) => x.id === bannerId)
            : 0
        }
        speed={500}
        slidesToShow={1}
        centerMode={centerMode}
        centerPadding={"350px"}
        afterChange={afteChange}
        beforeChange={beforeChange}
        swipeToSlide={true}
        dots={false}
        responsive={[
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 1,
              infinite: true,
              centerMode: centerMode,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 1,
              centerPadding: "280px",
              infinite: true,
              centerMode: centerMode,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              infinite: true,
              centerPadding: "150px",
              centerMode: centerMode,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              infinite: true,
              centerPadding: "120px",
              centerMode: centerMode,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              infinite: true,
              centerMode: false,
              dots: true,
              centerPadding: "0px",
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              infinite: true,
              centerMode: false,
              dots: true,
              centerPadding: "0px",
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              infinite: true,
              centerMode: false,
              dots: true,
              centerPadding: "0px",
            },
          },
        ]}
      >
        {loading
          ? initShimmer.map((res) => (
              <Shimmer type="banner" className="box_shimmer" key={res} />
            ))
          : data?.map((res, index) => (
              <Image
                src={res.images_page_website_url_original}
                className="img_carousel"
                key={index}
                onClick={() => redirectToApp(res.id)}
              />
            ))}
      </Carousel>
      {!loading && data!.length > 0 ? (
        <AbsoluteStyling>
          <Button onClick={() => slickMove("prev")} className="btn_arrow">
            <Text className="arrow left" />
          </Button>
          <div className="wrapped_arrow" />
          <Button onClick={() => slickMove("next")} className="btn_arrow right">
            <Text className="arrow right" />
          </Button>
        </AbsoluteStyling>
      ) : null}
      {/* <Shimmer /> */}
    </Container>
  );
};

export default Index;
