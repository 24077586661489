import React, { useRef } from "react";
import {
  Container,
  PackStyling,
  AbsoluteStyling,
  CarouselWrapper,
  ProductDetailMain,
  ImageChildStyling,
} from "./style";
import { Row, Col, Carousel, Image } from "antd";
import { Image as Images, Text, Button } from "components";
import useProductDetail from "./useProductDetail";
import { hasObjectValue, currency } from "helpers";

type ProductDetailProps = {
  product?: any;
  loading?: boolean;
};

const ProductDetail = ({ product, loading }: ProductDetailProps) => {
  const sliderRef = useRef<any>(null);
  const { imgActive, slickMove, afteChange, goToSlide } = useProductDetail({
    sliderRef,
  });
  return (
    <Container>
      <Row>
        <Col xl={10} lg={10} md={10} xs={24}>
          <CarouselWrapper>
            <Carousel ref={sliderRef} afterChange={afteChange}>
              {loading
                ? null
                : hasObjectValue(product, "data") &&
                  product.data.map((res: any, index: number) => {
                    return (
                      hasObjectValue(res, "images_sizes_url") &&
                      res.images_sizes_url.original.map(
                        (img: string, numb: number) => (
                          <div className="img_item">
                            <Image src={img} key={index} />
                          </div>
                        )
                      )
                    );
                  })}
            </Carousel>
            {hasObjectValue(product, "data") &&
              product.data.length !== 0 &&
              product.data.map(
                (res: any) => res.images_sizes_url.original.length
              ) > 1 && (
                <AbsoluteStyling>
                  <Button
                    className="btn_arrow"
                    onClick={() => slickMove("prev")}
                  >
                    <Text className="arrow left" />
                  </Button>
                  <div className="wrapped_arrow" />
                  <Button
                    className="btn_arrow right"
                    onClick={() => slickMove("next")}
                  >
                    <Text className="arrow right" />
                  </Button>
                </AbsoluteStyling>
              )}
          </CarouselWrapper>
          <ImageChildStyling>
            {loading
              ? null
              : hasObjectValue(product, "data") &&
                product.data.map((res: any) => {
                  return (
                    hasObjectValue(res, "images_sizes_url") &&
                    res.images_sizes_url.original.map(
                      (img: string, index: number) => (
                        <React.Fragment key={index}>
                          <Images
                            className={`img_item_small ${
                              imgActive === index ? "bordered" : ""
                            }`}
                            src={img}
                            onClick={() => goToSlide(index)}
                          />
                        </React.Fragment>
                      )
                    )
                  );
                })}
          </ImageChildStyling>
        </Col>
        <Col xl={14} lg={14} md={14} xs={24}>
          {loading
            ? null
            : hasObjectValue(product, "data") &&
              product.data.map((res: any, index: number) => {
                const banner_harga_jual = hasObjectValue(
                  res,
                  "banner_harga_jual"
                )
                  ? res.banner_harga_jual
                  : null;
                const on_promo =
                  hasObjectValue(res, "on_promo") && res.on_promo;
                const promo_price =
                  hasObjectValue(res, "promo_price") && res.promo_price
                    ? res.promo_price
                    : 0;
                let finalPrice = 0;
                let price = res.price;
                if (on_promo === 1) {
                  if (banner_harga_jual !== null) {
                    finalPrice = banner_harga_jual;
                  } else {
                    finalPrice = promo_price;
                  }
                } else {
                  finalPrice = price;
                }
                return (
                  <ProductDetailMain key={index}>
                    <Text className="promo_name" text={res.name} />
                    <Text
                      className="short_description"
                      text={res.short_description}
                    />
                    <Text className="price" text={currency(res.price)} />
                    <PackStyling>
                      <Text
                        className="promo_price"
                        text={currency(finalPrice)}
                      />
                      <Text className="slash" text="/" />
                      <Text className="pack" text="Pack" />
                    </PackStyling>
                    <Text className="description" text={res.description} />
                  </ProductDetailMain>
                );
              })}
        </Col>
      </Row>
    </Container>
  );
};
export default ProductDetail;
