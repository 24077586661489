import styled from "styled-components";
import { Colors, Fonts } from 'constant';


const Container = styled.div`
    margin-top: 10px;
    width:100%;
    .banner_bottom {
        box-shadow: 0 -10px 20px 0 rgba(236, 236, 236, 2);
        position: fixed;
        bottom: 0px;
        z-index: 10;
        background-color: white;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center

`;

const RectLeft = styled.div`
    position: relative;
    .triangle_left {
        position: absolute;
        right: 0;
        width: 0;
        height: 0;
        text-indent: -9999px;
        border-top: 80px solid #FFF3F5;
        border-left: 150px solid transparent;
        top: -2px;
        @media screen and (max-width: 480px) {
            border-top: 35px solid #FFF3F5;
            border-left: 60px solid transparent;
        }
    }

`;

const RectRight = styled.div`
    position: relative;
    .triangle_right {
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        bottom: -15px;
        text-indent: -9999px;
        border-bottom: 80px solid #FFF3F5;
        border-right: 150px solid transparent;
        @media screen and (max-width: 480px) {
            border-bottom: 36px solid #FFF3F5;
            border-right: 60px solid transparent;
        }
    }
`;

const RectCenter = styled.div`
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${ Colors.white.default };
    .all_product_info {
        color: ${ Colors.red.custom };
        font-size: ${ Fonts.sizes.m2 };
        margin-bottom: 20px;
        margin-top: 0px;
        font-family: ${ Fonts.avenirHeavy };
    }
    .btn_download {
        width: 268px;
        background-color: ${ Colors.red.custom };
        text-align: center;
        padding: 12px 0;
        background-image: linear-gradient(90deg, #D9003E 0%, #F90040 100%);
        border-radius: 8px;
        box-shadow: 5px 8px 10px 0 rgba(229, 37, 70, 0.5);
        p {
            color: ${ Colors.white.default };
            margin: auto;
            font-size: ${ Fonts.sizes.s2 };
            font-family: ${ Fonts.avenirHeavy };
        }
    }
    @media screen and (max-width: 480px) {
        padding: 20px 0;
        .all_product_info {
            font-size: ${ Fonts.sizes.m };
            margin-bottom: 8px;
        }
        .btn_download {
            width: 268px;
            p {
                font-size: ${ Fonts.sizes.s };
            }
        }
    }
`;


export { Container, RectLeft, RectRight, Wrapper, RectCenter };
