/* eslint-disable no-undef */
export function clearToken() {
  return localStorage.removeItem('token');
}

export function getToken() {
  return localStorage.getItem('token');
}

export function setTokenUser(token: string) {
  return localStorage.setItem('token', token);
}

export function setUserStorage(user: any) {
  localStorage.setItem('token', user.token);
}

export function clearStorage() {
  localStorage.removeItem('token');
}