import React from "react";

interface CardProps {
  children?: React.ReactNode;
  classname?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Card = ({
  children,
  classname,
  onClick,
  onMouseEnter,
  onMouseLeave,
  ...props
}: CardProps) => {
  return (
    <div
      className={classname}
      onClick={onClick}
      {...props}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};

export default Card;
