import styled from "styled-components";
import { Colors } from 'constant';

type ContainerProps = {
    isBottom: boolean;
};

const Container = styled.div<ContainerProps>`
    width:100%;
    
    .banner {
        background: ${ Colors.red.custom };
        width: 100%;
    }
    ${ props => props.isBottom &&
        `
        padding-bottom: 10rem;
        @media screen and (max-width: 768px) {
            padding: 0px 0px 10rem 0;
    
        }`

    }

    .button-margin {
        margin: 0px 10px;
        border-radius: 10px;
        @media screen and (max-width: 320px) {
            margin: 0 1px;
        }
    }

`;


export { Container };
