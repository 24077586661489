import { Branch } from "interfaces";
import { BranchActionTypes } from "./action-types";
import * as actions from './actions'

const initialState: Branch.State = {
    list: {},
    loadingGet: false,
    error: false,
    branch_id: '1'
}

const setBranches = (state: Branch.State, action: ReturnType<typeof actions.getBranchSuccess>) => ({
    ...state,
    list: action.payload.data,
    loadingGet: false
})

const setBranchID = (state: Branch.State, action: ReturnType<typeof actions.setBranchID>) => ({
    ...state,
    branch_id: action.payload
})

const setPending = (state: Branch.State, action: ReturnType<typeof actions.getBranchPending>) => ({
    ...state,
    loadingGet: true
})

const branchReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case BranchActionTypes.GET_BRANCH_SUCCESS:
            return setBranches(state, action)
        case BranchActionTypes.GET_BRANCH_PENDING:
            return setPending(state, action)
        case BranchActionTypes.SET_BRANCH_ID:
            return setBranchID(state, action)
        default:
            return state;
    }
}

export default branchReducer;