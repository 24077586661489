import styled from "styled-components";
import { Colors, Fonts } from 'constant';
import { Images } from 'constant'


const Container = styled.div`
    padding: 20px 85px;
    overflow: hidden;
    @media screen and (max-width: 960px) {
        padding: 20px 45px;
    }
    @media screen and (max-width: 768px) {
        padding: 20px 20px;
    }
`;

const StylingMargin = styled.div`
    margin: 20px 0;
    @media screen and (max-width: 600px) {
        margin: 0;
    }
`
const Wrapped = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .promo_name {
        color: rgba(81, 81, 81, 0.9);
        font-size: ${Fonts.sizes.m4};
        margin: 0;
        font-family: ${Fonts.avenirHeavy};
    }

    .input_search {
        width: 350px;
        padding: 7px 15px;
        font-size: ${Fonts.sizes.s2};
        color: ${Colors.black.lighter};
        outline: none;
        border-radius: 10px;
        border: 1px solid ${Colors.grey.small};
        font-family: ${Fonts.avenirRoman};
        box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1); 
    }
    .input_search::placeholder {
        font-size: ${Fonts.sizes.s2};
        background-image: url('${Images.icon_search?.default}');
        background-size:20px 20px;
        background-repeat: no-repeat;
        background-position: center left;
        padding-left: 30px;
        opacity: 0.5;
        font-family: ${Fonts.avenirRoman};
    }
    .input_search[type=text] {
        border-color: rgba(229, 103, 23, 0.8);
        box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px rgba(229, 103, 23, 0.6);
        outline: 0 none;
    }
    .input_search:focus::placeholder {
        // background-image: none;
    }
    @media screen and (max-width: 480px) {
        flex-direction: column;
        display: block;
        .promo_name {
            color: ${Colors.black.lighter};
            font-size: ${Fonts.sizes.m1};
            margin-bottom: 10px
        }
        .input_search {
            width: 100%;
            font-size: ${Fonts.sizes.xs};
            padding: 10px 20px;
            box-shadow: none; 
        }
        .input_search::placeholder {
            font-size: ${Fonts.sizes.xs};
            padding-left: 30px;
            background-size:16px 16px;
        }
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0 20px; 

    .button {
        margin-right: 20px;
        border-radius: 20px;
        padding: 3px 10px;
        p {
        margin: 0;
        font-size: ${Fonts.sizes.s2};
        font-family: ${Fonts.avenirHeavy};
        }
    }
    

    .active {
        background-color: ${Colors.red.custom};
        color: ${Colors.white.default};
    }
    .inactive {
        background-color: ${Colors.grey.light};
        color: ${Colors.red.custom};
    }

    @media screen and (max-width: 480px) {
        overflow-y: scroll;
        display: -webkit-inline-box;
        box-sizing: content-box;
        margin: 20px 0 10px;
        ::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* make scrollbar transparent */
        }
        .button {
            p {
            font-size: ${Fonts.sizes.xs};
            }
        }
        .inactive {
            background-color: ${Colors.red.light};
            color: ${Colors.red.custom};
        }
    }
    
`


export { Container, Wrapped, ButtonWrapper, StylingMargin };
