import React from 'react'
import {Images} from 'constant'
import { Container, TopText, BottomText, DownloadButton, ImagePersonWithCart, ImageContainer } from './style'
import { openApp } from "helpers";
import { BannerDetail } from 'interfaces/banners/banner-detail';
import PersonWithCart from '../../../assets/images/person_with_cart.png'

interface BannerBottomProps {
    data: BannerDetail;
}

const EmptyProductContainer = ({ data }: BannerBottomProps) => {

    const redirectToApp = () => {
        openApp("1", data.id);
    };

    return (
        <Container>
            <ImageContainer>
                <ImagePersonWithCart src={PersonWithCart} />
            </ImageContainer>
           <TopText>We are waiting for you in FreshBox App</TopText>
           <BottomText>Groceries shopping experience has never been this easy!</BottomText>
           <DownloadButton onClick={() => redirectToApp()}>Download FreshBox App</DownloadButton>
        </Container>
    )
}

export default EmptyProductContainer
