export const objectToQuery = (params: any) => {
    let str = "";
    for (let key in params) {
        if (params[key] !== null) {
            if (str !== "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(params[key]);
        }
    }
    
    return str
}
export default objectToQuery
