import React from "react";
import { StyledButton } from "./style";

interface ButtonProps {
  children?: React.ReactNode;
  background?: string;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  className,
  background,
  onClick,
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      background={background}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
