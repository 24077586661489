import styled from "styled-components";
import {Screen} from 'helpers'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 75px;
`

const TopText = styled.p`
    color: #515151;
    font-family: Avenir;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
`;

const BottomText = styled.p`
    color: #515151;
    font-family: Avenir;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
    text-align: center;
    @media (max-width: ${Screen.size.xs}) {
        font-size: 14px;
    }
`;

const DownloadButton = styled.button`
    background-image: linear-gradient(90deg, #D9003E 0%, #F90040 100%);
    border-radius: 8px;
    box-shadow: 5px 8px 10px 0 rgba(229, 37, 70, 0.5);
    width: 313px;
    height: 60px;
    color: #FFFFFF;
    font-family: Avenir;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    cursor: pointer !important;
`

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
`

const ImagePersonWithCart = styled.img`
    width: 100%;
    height: 100%;
`

export {Container, TopText, BottomText, DownloadButton, ImagePersonWithCart, ImageContainer }