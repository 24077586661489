import { createGlobalStyle } from 'styled-components';
import Sizes from './Sizes';

export default createGlobalStyle`
  .p-0 {
    padding: 0 !important;
  }

  .shadow {
    border:2px solid #fff;
    // background: url(img/tiger.png) no-repeat;
    box-shadow: 10px 10px 5px #ccc;
    -moz-box-shadow: 10px 10px 5px #ccc;
    -webkit-box-shadow: 10px 10px 5px #ccc;
    -khtml-box-shadow: 10px 10px 5px #ccc;
  }

  .border-radius-small {
    border-radiuse: 10px
  }
  .border-radius-large {
    border-radiuse: 22px
  }

  @media ${Sizes.md}{
    .md-p-0 {
      padding: 0 !important;
    }

  }

`;