import styled from "styled-components";
import { Colors, Fonts } from 'constant';


const Container = styled.div`
    width: 100%;
    padding: 20px 85px;
    .slick-slider {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
      }

    .slick-slide.slick-center img {
        transform: scale(1.20);
        transition: transform 0.8s;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
      touch-action: pan-y;
    }

    .promo_name {
        font-size: 28px;
        font-weight: 700;
        color: rgba(81, 81, 81, 0.9);
        // height: 58px;
        font-family: ${Fonts.avenirHeavy};
        margin-bottom: 10px;
        // line-height: 30px;
        letter-spacing: -0.0em;
    }
    .ant-carousel .slick-dots li button {
        display: none;
    }
    .short_description {
        font-size: ${Fonts.sizes.s2};
        color: rgba(154, 154, 154, 0.9);
        position: relative;
        font-family: ${Fonts.avenirRoman};
        font-weight: 100;
    }
    .price {
        margin-bottom: 0;
        font-size: ${Fonts.sizes.s2};
        color: rgba(154, 154, 154, 0.9);
        position: relative;
        font-family: ${Fonts.avenirRoman};
        font-weight: 100;
    }
    .price::after {
        border-bottom: 1px solid #9A9A9A;
        content: "";
        left: 0;
        line-height: 1em;
        margin-top: calc(0.125em / 2 * -1);
        position: absolute;
        width: 88px;
        top: 54%;
    }
    .promo_price {
        font-size: 19px;
        font-weight: 700;
        color: rgba(81, 81, 81, 0.9);
        font-family: ${Fonts.avenirHeavy};
        letter-spacing: -0.02em;
    }
    .description {
        font-size: ${Fonts.sizes.s2};
        color: rgba(154, 154, 154, 0.9);
        position: relative;
        font-family: ${Fonts.avenirRoman};
        font-weight: 100;
    }
    .img_item {
        width: 320px;
        height: 300px;
    }
    .img_item_small {
        width: 100px;
        height: 100px;
    }
    .bordered {
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    @media screen and (max-width: 960px) {
        padding: 20px 45px;
    }
    @media screen and (max-width: 768px) {
        padding: 0;
        .promo_name {
            font-size: 22px;
        }
        .img_item {
            width: 100%;
            height: auto;
        }
    }
    @media screen and (max-width: 600px) {
        .box_shimmer {
            width: 100%;
            height: 300px;
            border-radius: 0px;
        }
        .img_item {
            text-align:center;
        }
        .ant-image-img {
            width: 250px;
        }
        .ant-carousel .slick-dots li button {
            display: block;
            width: 10px !important;
            height: 10px !important;
            border-radius: 100%;
        }
        .ant-carousel .slick-dots li.slick-active button {
            background: ${Colors.red.custom}
        }
        .ant-carousel .slick-dots li button {
            background: ${Colors.black.lighter}
        }
    }
    // @media screen and (max-width: 480px) {
    //     .img_item {
    //         text-align:center;
    //     }
    //     .ant-image-img {
    //         width: 250px;
    //     }
    //     .ant-carousel .slick-dots li button {
    //         width: 10px !important;
    //         height: 10px !important;
    //         border-radius: 100%;
    //     }
    //     .ant-carousel .slick-dots li.slick-active button {
    //         background: ${Colors.red.custom}
    //     }
    //     .ant-carousel .slick-dots li button {
    //         background: ${Colors.black.lighter}
    //     }
    // }
`;
const PackStyling = styled.div`
    display: flex;
    flexDirection: row;
    margin-bottom: 20px;
    align-items: center;
    p {
        color: rgba(154, 154, 154, 0.9);
        font-size: 14px;
        margin: 0;
    }
    .slash {
        margin-left: 5px;
    }
    .pack {
        margin-left: 2px;
        margin-top: 3px;
        font-family: ${Fonts.avenirRoman}
    }
    @media screen and (max-width: 480px) {
        p {
            font-size: ${Fonts.sizes.xss};
        }
        .pack {
            margin-top: 0px;
        }
    }
`
const AbsoluteStyling = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: 50%;
    height: 0px;
    .btn_arrow {
        background-color: rgba(81, 81, 81, 0.4);
        border-radius: 100%;
        box-shadow: 0 5px 10px 0 rgba(229, 37, 70, 0.1);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .arrow {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-top: 2px solid rgba(255, 255, 255, 0.95);
        border-right: 2px solid rgba(255, 255, 255, 0.95);
        margin: 0;
      }
    .left {
        transform: rotate(-135deg);
      }
      .right {
        transform: rotate(22deg);
      }
      @media screen and (max-width: 800px) {
        display: none;
    }
`
const CarouselWrapper = styled.div`
    width: 320px;
    position:relative;
    @media screen and (max-width: 800px) {
        width: 100%;
    }
`

const ProductDetailMain = styled.div`
    @media screen and (max-width: 800px) {
        padding: 20px;
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 4rem;
    }
`
const ImageChildStyling = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 800px) {
        padding: 20px;
    }
`


export { Container, PackStyling, AbsoluteStyling, CarouselWrapper, ProductDetailMain, ImageChildStyling };
