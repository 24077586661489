export default {
	white: {
		default: 'rgb(255, 255, 255)',
	},
	black: {
		default: 'rgb(38, 38, 38)',
		lighter: 'rgb(81, 81, 81)'
	},
	grey: {
		default: 'rgb(128, 128, 128)',
		light: 'rgb(234, 234, 234)',
		lighter: 'rgb(245, 245, 245)',
		dark: 'rgb(67, 67, 67)',
		small: 'rgb(208, 208, 208)',
		medium: 'rgb(180, 180, 180)'
	},
	red: {
		default: 'rgb(255, 0, 0)',
		light: 'rgb(255, 242, 243)',
		custom: 'rgb(229, 37, 70)'
	},
	blue: {
		default: 'rgb(26, 66, 120)',
		light: 'rgb(232, 236, 242)',
		dark: 'rgb(58, 91, 135)'
	},
	yellow: {
		default: 'rgb(255, 255, 0)',
	},
	green: {
		default: 'rgb(0, 128, 0)',
		tosca: 'rgb(6, 214, 159)',
		toscaLight: 'rgb(176, 227, 214)'
	},
	orange: {
		default: 'rgb(255, 166, 0)',
		light: 'rgb(239, 233, 231)'
	},
	pink: {
		default: 'rgb(255, 192, 203)',
	},
	gold: {
		default: 'rgb(221, 143, 25)',
	},
};
