/* eslint-disable no-undef */

import { getToken } from './localStorage';

const customFetch = async (url: string, method: string, data?: any, isUpload?: any) => {
  const token = getToken()
  let headers: any = {};
  const headerDict = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; application/x-www-form-urlencoded; charset=UTF-8'
  }
  if (!url.includes('login') && !url.includes('forgot-password')) {
    if (!isUpload) {
      headers = headerDict
      if (token !== null) {
        const auth = {
          Authorization: token
        }
        headers = { ...headers, ...auth }
      }
    }
    // headers.Authorization = getToken();
  }

  try {
    const sendData = isUpload ? data : JSON.stringify(data);
    const response = await fetch(url, {
      method: method || 'GET',
      body: sendData,
      mode: 'cors',
      headers: headers,
    });
    switch (response.status) {
      case 401:
        if (!window.location.pathname.includes('performance-mc')) {
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          window.location.reload();
        } else {
          window.location.replace('/401');
        }
        break;
      case 403:
        window.location.replace('/403');
        break;
      case 400:
        const res = await response.json();
        if (res.stat_msg.toLowerCase() === 'invalid token') {
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          window.location.replace('/signin');
        } else {
          return res;
        }
        break;
      case 500:
        window.location.replace('/500');
        break;

      default:
        return response;
    }
  } catch (err) {
    throw err;
  }
};
export default customFetch;
