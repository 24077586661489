import { Common } from 'interfaces';
import { Endpoints } from 'constant';
import { customFetch, objectToQuery } from 'helpers';

export const getBanners = async (payload: any) => {
    let params = objectToQuery(payload.params)
    try {
        const response = await customFetch(`${Endpoints.url}${Endpoints.banner}?${params}`, 'get');
        const res = await response.json();
        return res;
    } catch (error) {
        throw error;
    }
};

export const getBannerDetail = async (params: Common.ApiParams) => {
    try {
        const response = await customFetch(`${Endpoints.url}${Endpoints.banner}${params.id}?branch_id=${params.branch_id}`, 'get');
        const res = await response.json();
        return res;
    } catch (error) {
        throw error;
    }
}
