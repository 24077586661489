import React from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "./style";
import { RootState } from "interfaces/common";
import { Text, Button, Image } from "components";
import { onGetBannerDetailAction } from "store/banners/actions";
import { onGetProduct } from "store/product/actions";
import { Images } from "constant";
import { hasObjectValue, openApp, queryToString } from "helpers";
import ProductDetail from "./ProductDetail";
import { setBranchID } from "store/branches/actions";

const Links = (props: LinksProps) => {
  const {
    onGetBannerDetailAction,
    onGetProduct,
    banner_detail,
    product,
    location,
  } = props;
  const value: any = queryToString(location.search);
  const [load, setLoading] = React.useState(true);

  const dispatch = useDispatch()

  React.useEffect(() => {
    
    if (value.code_link && value.code_link === "1") {
      onGetBannerDetailAction({
        id: atob(value.code_data),
        branch_id: value.branch_id
      });
      dispatch(setBranchID(value.branch_id))
    } else if (value.code_link && value.code_link === "2") {
      let payload = {
        params: {
          product_code: atob(value.code_data),
          branch_id: value.branch_id
        },
      };
      onGetProduct(payload);
      dispatch(setBranchID(value.branch_id))
    } else {
      props.history.push("/");
      dispatch(setBranchID(value.branch_id))
    }
  }, []);

  React.useEffect(() => {
    if (banner_detail.id !== 0) {
      props.history.push(
        `/catalog?type=1&data=${banner_detail.id}&type_banner=${banner_detail.type}`
      );
    } else {
      let isEmpty: any = hasObjectValue(product.list, "data") && product.list;
      if (isEmpty !== false && isEmpty.data.length === 0) {
        props.history.push("/");
      }
      setLoading(false);
    }
  }, [banner_detail, product]);

  const redirectToApp = (id: any) => {
    openApp("2", id, value.branch_id);
  };

  const renderUI = () => {
    if (value.code_link === "2") {
      let isEmpty: any = hasObjectValue(product.list, "data") && product.list;
      return (
        <>
          <ProductDetail product={product.list} loading={product.loadingGet} />
          {hasObjectValue(product.list, "data") && isEmpty.data.length !== 0 && (
            <Button
              className="btn_launch"
              onClick={() => redirectToApp(atob(value.code_data))}
            >
              <Text text="Buka Aplikasi Freshbox" />
            </Button>
          )}
        </>
      );
    } else return <div className={"load"}></div>;
  };
  return (
    <Container>
      {banner_detail.loading || product.loadingGet ? (
        <div className={"load"}>
          <Image src={Images.logo?.default} className="logo_loading" />
        </div>
      ) : load ? (
        <div className={"load"}></div>
      ) : (
        renderUI()
      )}
    </Container>
  );
};

interface EventDispatch {
  onGetBannerDetailAction: Function;
  onGetProduct: Function;
}
const mapDispatchToProps: EventDispatch = {
  onGetBannerDetailAction,
  onGetProduct,
};

const mapStateToProps = (state: RootState) => ({
  banner_detail: state.banner_detail.banner_detail,
  product: state.product,
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type EnhancedProps = StateProps & DispatchProps;

type LinksProps = OwnProps & EnhancedProps & RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(Links);
