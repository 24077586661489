import React from "react";
import { Container, RectLeft, RectRight, Wrapper, RectCenter } from "./style";
import { Banner, Button, Text } from "components";
import { openApp } from "helpers";
import { BannerDetail } from "interfaces/banners/banner-detail";

interface BannerBottomProps {
  data: BannerDetail;
}
const Index = ({ data }: BannerBottomProps) => {
  const redirectToApp = () => {
    openApp("1", data.id);
  };
  return (
    <Container>
      <Banner className="banner_bottom shadow">
        <RectLeft>
          <div className="triangle_left" />
        </RectLeft>
        <Wrapper>
          <RectCenter>
            <Text
              text="Lihat Semua Produk Promo di Aplikasi FreshBox!"
              className="all_product_info"
            />
            <Button className="btn_download" onClick={ () => redirectToApp() }>
              <Text text="Buka Aplikasi FreshBox" />
            </Button>
          </RectCenter>
        </Wrapper>
        <RectRight>
          <div className="triangle_right" />
        </RectRight>
      </Banner>
    </Container>
  );
};

export default Index;
