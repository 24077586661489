import { Common } from 'interfaces';
import { Endpoints } from 'constant';
import { customFetch, objectToQuery } from 'helpers';
import * as setData from './product.setData';

export const getProduct = async (payload: any) => {
  let params = objectToQuery(payload.params)
  try {
    const response = await customFetch(`${Endpoints.url}${Endpoints.product}?${params}`, 'get');
    const res = await response.json();
    return res;
  } catch (error) {
    return error
  }
}
