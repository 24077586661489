import React from "react";
import { Container, Wrapped, ButtonWrapper, StylingMargin } from "./style";
import { Text, TextInput, Button, Shimmer } from "components";
import ListItem from "./list-item";
import { BannerDetail } from "interfaces/banners/banner-detail";
import { hasObjectValue } from "helpers/has-value";
import { Product, ProductOfBanner } from "interfaces/product";
import useProduct from "./useProduct";
interface ProductProps {
  data: BannerDetail;
  product: Product[];
  productBanner: ProductOfBanner[];
  getProduct: (params: any) => void;
  bannerId: number;
  loading: boolean;
  bannerLoading?: boolean;
  type?: number | undefined;
  onSearchProduct: any;
  branchId?: string;
}

const ProductComponent = ({
  data,
  product,
  getProduct,
  bannerId,
  loading,
  bannerLoading,
  type,
  onSearchProduct,
  productBanner,
  branchId
}: ProductProps) => {
  const {
    text,
    onChangeText,
    onPressCategory,
    renderCategory,
    btnActive,
    setButton,
  } = useProduct({
    getProduct,
    data,
    bannerId,
    type,
    onSearchProduct,
    
  });

  const renderButton = () => {
    if (hasObjectValue(data, "new_products") && !data.loading) {
      let element: any = renderCategory();
      return element.map((e: any, index: number) => (
        <Button
          key={`btn-${index}`}
          className={`button ${index === btnActive ? "active" : "inactive"}`}
          onClick={() => {
            setButton(index);
            onPressCategory(e);
          }}
        >
          <Text
            text={e}
            className={`button ${index === btnActive ? "active" : "inactive"}`}
          />
        </Button>
      ));
    } else return <StylingMargin />;
  };

  return (
    <Container>
      <Wrapped>
        {data.loading ? (
          <Shimmer type="line" className="promo_name" />
        ) : (
          <Text
            text={data.name_banner ? data.name_banner : "\n"}
            className="promo_name"
          />
        )}
        {!data.loading && data.type === 3 ? (
          <TextInput
            placeholder="Cari produk"
            className="input_search"
            onChange={(e) => onChangeText(e.target.value)}
            value={text}
          />
        ) : null}
      </Wrapped>
      <ButtonWrapper>{renderButton()}</ButtonWrapper>
      <ListItem
        items={product}
        loading={loading}
        bannerDetail={data}
        productBanner={productBanner}
        branchId={branchId}
      />
    </Container>
  );
};

export default ProductComponent;
