import React from "react";

interface ImageProps {
  className?: string;
  src?: string;
  alt?: string;
  onClick?: () => void;
  key?: number;
}

const Image = ({ className = "", src, alt = "", onClick, key }: ImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onClick={onClick}
      key={key}
    />
  );
};

export default Image;
