import { Common, Branch } from 'interfaces';
import { BranchActionTypes } from './action-types'
import * as BranchServices from 'services/branches/branches'

export const getBranchSuccess = (payload: { data: Branch.Branch[] }) => ({
    type: BranchActionTypes.GET_BRANCH_SUCCESS,
    payload,
})

export const getBranchPending = () => ({
    type: BranchActionTypes.GET_BRANCH_PENDING,
});

export const setBranchID = (payload: string) => (
    {
        type: BranchActionTypes.SET_BRANCH_ID,
        payload
    }
)

export const onGetBranch = () => (dispatch: (arg0: any) => void) => {
    return new Promise<void>(async (resolve, reject) => {
        dispatch(getBranchPending())
        
        const result: Common.ApiResponseData<Branch.Branch[]> = await BranchServices.getBranches();
        
        const { code, data, code_message, code_type, pagination } = result

        if (code === 200) {
            dispatch(getBranchSuccess({ data }))
            resolve()
        } else {
            reject(code_message)
        }
    })
}