import React from "react";
import {
  Container,
  Row,
  CardBody,
  PackStyling,
  CardImage,
  PercentageStyling,
} from "./style";
import { Card, Image, Text, Shimmer } from "components";
import { BannerDetail } from "interfaces/banners/banner-detail";
import { currency, hasObjectValue, trimOn, openApp } from "helpers";
import { Product, ProductOfBanner } from "interfaces/product";
import PopUp from "../../pop-up";

interface ListItemProps {
  items: any;
  loading: boolean;
  bannerDetail: BannerDetail;
  productBanner: ProductOfBanner[];
  branchId: string | undefined;
}

const Index = ({
  items,
  loading,
  bannerDetail,
  productBanner,
  branchId
}: ListItemProps) => {
  const initShimmer = [1, 2, 3, 4, 5];
  const [active, setActive] = React.useState(-1);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [productCode, setProductCode] = React.useState({});

  const redirectToApp = (id: any) => {
    openApp("2", id, branchId);
  };

  return (
    <Container>
      {bannerDetail.type !== 3 && productBanner.length === 0 && !loading
        ? bannerDetail.syarat_ketentuan.split("\n").map((item, key) => {
            return (
              <span key={key} className="description">
                {item}
                <br />
              </span>
            );
          })
        : null}
      <Row>
        {loading
          ? initShimmer.map((res) => <Shimmer type="card" key={res} />)
          : bannerDetail.type === 3
          ? hasObjectValue(items, 'data') && items.data.map((el: any, index: number) => {
              return (
                <Card
                  classname="card"
                  key={el.code}
                  onClick={() => redirectToApp(el.code)}
                >
                  <CardImage>
                    <Image
                      src={el.images_sizes_url.original[0]}
                      className="card_img"
                    />
                  </CardImage>
                  <CardBody>
                    <Text text={el.name} className="name" />
                    <PercentageStyling>
                      <Text text={currency(el.price)} className="price" />
                      <Text
                        text={`Save ${el.discount_percentage}%`}
                        className="price_percentage"
                      />
                    </PercentageStyling>
                    <PackStyling>
                      <Text
                        text={currency(
                          el.banner_harga_jual
                            ? el.banner_harga_jual
                            : el.promo_price
                        )}
                        className="promo_price"
                      />
                      <Text text="/" className="slash" />
                      <Text text={el.unit} className="pack" />
                    </PackStyling>
                  </CardBody>
                </Card>
              );
            })
          : productBanner.length > 0 &&
            productBanner.map((res, index: number) => {
              const banner_harga_jual = hasObjectValue(res, "banner_harga_jual")
                ? res.banner_harga_jual
                : null;
              const on_promo =
                hasObjectValue(res.product, "on_promo") && res.product.on_promo;
              const promo_price =
                hasObjectValue(res.product, "promo_price") &&
                res.product.promo_price
                  ? res.product.promo_price
                  : 0;
              let finalPrice = 0;
              let price = res.product.price;
              if (on_promo === 1) {
                if (banner_harga_jual !== null) {
                  finalPrice = banner_harga_jual;
                } else {
                  finalPrice = promo_price;
                }
              } else {
                finalPrice = price;
              }
              return (
                <Card
                  classname="card"
                  key={res.product.code}
                  onClick={() => redirectToApp(res.product.code)}
                >
                  <CardImage>
                    <Image
                      src={res.product.images_sizes_url.original![0]}
                      className="card_img"
                    />
                  </CardImage>
                  <CardBody>
                    <Text
                      text={trimOn(res.product.name, 17)}
                      className={"name"}
                    />
                    <PercentageStyling>
                      <Text
                        text={currency(res.product.price)}
                        className={
                          res.product.discount_percentage > 0
                            ? "price"
                            : "price_no_promo"
                        }
                      />
                      {res.product.discount_percentage > 0 ? (
                        <Text
                          text={`Save ${res.product.discount_percentage}%`}
                          className="price_percentage"
                        />
                      ) : null}
                    </PercentageStyling>
                    <PackStyling>
                      <Text
                        text={currency(finalPrice)}
                        className="promo_price"
                      />
                      <Text text="/" className="slash" />
                      <Text text={res.product.unit} className="pack" />
                    </PackStyling>
                  </CardBody>
                </Card>
              );
            })}
      </Row>
      {/* <PopUp
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        productCode={productCode}
      /> */}
    </Container>
  );
};

export default Index

// const renderProduct = () => {
//   let element: any = [];
//   if (
//     typeof product.new_products === "object" &&
//     Object.keys(product.new_products).length > 0
//   ) {
//     const data = [product.products!, product.new_products];
//     if (category === 0) {
//       data[category].map((el: any, index: number) => {
//         element.push(
//           <Card classname="card" key={el.product.code}>
//             <Image
//               src={el.product.images_sizes_url.original[0]}
//               className="card_img"
//             />
//             <CardBody>
//               <Text text={el.product.name} className="name" />
//               <Text text={currency(el.product.price)} className="price" />
//               <PackStyling>
//                 <Text
//                   text={currency(el.product.promo_price)}
//                   className="promo_price"
//                 />
//                 <Text text=" / " className="slash" />
//                 <Text text="pack" className="pack" />
//               </PackStyling>
//             </CardBody>
//           </Card>
//         );
//       });
//     } else {
//       let items = data[category][name];
//       for (const obj in items) {
//         if (obj !== "info") {
//           element.push(
//             <Card classname="card" key={items[obj].product.code}>
//               <Image
//                 src={items[obj].product.images_sizes_url.original[0]}
//                 className="card_img"
//               />
//               <CardBody>
//                 <Text text={items[obj].product.name} className="name" />
//                 <Text
//                   text={currency(items[obj].product.price)}
//                   className="price"
//                 />
//                 <PackStyling>
//                   <Text
//                     text={currency(items[obj].product.promo_price)}
//                     className="promo_price"
//                   />
//                   <Text text=" / " className="slash" />
//                   <Text text="pack" className="pack" />
//                 </PackStyling>
//               </CardBody>
//             </Card>
//           );
//         }
//       }
//     }
//   } else {
//     product.products?.map((el: any, index: number) => {
//       console.log(el.product.images_sizes_url);
//       element.push(
//         <Card classname="card" key={el.product.code}>
//           <Image
//             src={el.product.images_sizes_url.original[0]}
//             className="card_img"
//           />
//           <CardBody>
//             <Text text={el.product.name} className="name" />
//             <Text text={currency(el.product.price)} className="price" />
//             <PackStyling>
//               <Text
//                 text={currency(el.product.promo_price)}
//                 className="promo_price"
//               />
//               <Text text=" / " className="slash" />
//               <Text text="pack" className="pack" />
//             </PackStyling>
//           </CardBody>
//         </Card>
//       );
//     });
//   }
//   return element;
// };
