const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

const openApp = (code: string, id: number, branchID?: string) => {
    const device = getMobileOperatingSystem()
    if (code !== 'android' && code !== 'ios') {
        if (device === 'Android') {
            window.location.replace(`intent://${code}/${id}?branchID=${branchID}#Intent;scheme=freshboxapp;package=com.freshbox;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.freshbox;end`)
            // window.location.replace(`freshboxapp://${code}/${id}/${branchID}`)
        } else if (device === "iOS") {
            window.location.replace(`freshboxapp://${code}/${id}?branchID=${branchID}`)
            // window.location.replace('https://erhadna.page.link/erhadnaproduct')
            // setTimeout(() => {
            //     window.location.replace("https://apps.apple.com/us/app/freshbox-id/id1448126091")
            // }, 3000)
        } else {
            window.location.href = "https://frshbox.app.link/downloadnow"
        }
    } else {
        if (code === 'android') {
            window.location.replace(`intent://0/0/#Intent;scheme=freshboxapp;package=com.freshbox;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.freshbox;end`)
        } else if (code === 'ios') {
            window.location.replace(`freshboxapp://0/0/`)
            setTimeout(() => {
                window.location.replace("https://apps.apple.com/us/app/freshbox-id/id1448126091")
            }, 3000)
        } else {
            window.location.href = "https://frshbox.app.link/downloadnow"
        }
    }
}

export default openApp