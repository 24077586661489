interface Config {
  url: any;
  name: string;
  production: boolean;
}

const dev = {
  // eslint-disable-next-line no-undef
  url: process.env.REACT_APP_API_URL_DEV,
  name: 'Staging',
  production: false
};

const production = {
  // eslint-disable-next-line no-undef
  url: process.env.REACT_APP_API_URL_PROD,
  name: 'Production',
  production: true
};

let config: Config = {} as Config;

// eslint-disable-next-line no-undef
switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;

  case 'production':
    config = production;
    break;

  default:
    break;
}

export default {
  ...config
};
