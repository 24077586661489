import React from "react";
import { Container, BoxPhoto, Lines, Shine, BannerShine } from "./style";
const { innerWidth } = window;

interface ShimmerProps {
  className?: string;
  children?: React.ReactNode;
  type?: string;
}

const Shimmer = ({ className, type }: ShimmerProps) => {
  if (type === "card") {
    return (
      <Container>
        <Shine height="140px">
          <BoxPhoto
            width={innerWidth > 480 ? "180px" : ` ${innerWidth / 2 - 40}px`}
          />
        </Shine>
        <Shine marginTop={innerWidth > 480 ? "40px" : "20px"}>
          <Lines
            width={innerWidth > 480 ? "180px" : ` ${innerWidth / 2 - 40}px`}
          />
        </Shine>
        <Shine>
          <Lines width="80px" />
        </Shine>
        <Shine marginTop={innerWidth > 480 ? "25px" : "5px"}>
          <Lines width="150px" />
        </Shine>
        <Shine marginBottom="30px">
          <Lines
            width={innerWidth > 480 ? "180px" : ` ${innerWidth / 2 - 40}px`}
          />
        </Shine>
      </Container>
    );
  } else if (type === "banner") {
    return (
      <Container>
        <BannerShine className={className}>
          <BoxPhoto />
        </BannerShine>
      </Container>
    );
  } else if (type === "line") {
    return (
      <Container className={className}>
        <Shine height={innerWidth > 480 ? "50px" : "35px"} marginTop="0px">
          <Lines width={innerWidth > 480 ? "300px" : "150px"} />
        </Shine>
      </Container>
    );
  } else return null;
};

export default Shimmer;
