import styled from "styled-components";
import { Colors, Fonts } from 'constant';


const Container = styled.div`
    width: 100%;
    .card {
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(236, 236, 236, 2);
        width: 200px;
        height: 343px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 0 8px 10px 0;
    }
    // .card:hover {
    //     transform: scale(1.18);
    // }
    .card_img {
        width: 158px;
        height: 158px;
        margin: auto;
    }
    .name {
        font-size: 21px;
        font-weight: 700;
        color: rgba(81, 81, 81, 0.9);
        height: 58px;
        font-family: ${Fonts.avenirHeavy};
        margin-bottom: 10px;
        line-height: 30px;
        letter-spacing: -0.0em;
    }
    .name_hover {
        font-size: 21px;
        font-weight: 700;
        color: rgba(81, 81, 81, 0.9);
        height: 100px;
        font-family: ${Fonts.avenirHeavy};
        margin-bottom: 10px;
        line-height: 30px;
        letter-spacing: 0.1px;
    }

    .description {
        font-size: ${Fonts.sizes.m};
        font-weight: 700;
        color: rgba(81, 81, 81, 0.9);
        font-family: ${Fonts.avenirHeavy};
        margin-top: -40px;
    }

    .price_no_promo {
        margin-bottom: 0;
        font-size: ${Fonts.sizes.s2};
        color: rgba(154, 154, 154, 0.9);
        position: relative;
        font-family: ${Fonts.avenirRoman};
        font-weight: 100;
        letter-spacing: -0.02em;
    }

    .price {
        margin-bottom: 0;
        font-size: ${Fonts.sizes.s2};
        color: rgba(154, 154, 154, 0.9);
        position: relative;
        font-family: ${Fonts.avenirRoman};
        font-weight: 100;
        letter-spacing: -0.02em;
    }
    .price::after {
        border-bottom: 1px solid #9A9A9A;
        content: "";
        left: 0;
        line-height: 1em;
        margin-top: calc(0.125em / 2 * -1);
        position: absolute;
        right:0;
        top: 54%;
    }

    .price_percentage {
        background-color: ${Colors.red.light};
        font-size: ${Fonts.sizes.xss};
        color: ${Colors.red.custom};
        border-radius: 10px;
        padding: 2px 4px;
        margin-left: 7px;
        font-family: ${Fonts.avenirMedium};
        display: none;
    }
    .promo_price {
        font-size: 19px;
        font-weight: 700;
        color: rgba(81, 81, 81, 0.9);
        font-family: ${Fonts.avenirHeavy};
        letter-spacing: -0.02em;
    }
    @media screen and (max-width: 600px) {
        .card {
            width: 91%;
        }
        .card_img {
            width: 110px;
            height: 120px;
            margin: auto;
        }
        .name {
            font-size: ${Fonts.sizes.xs};
            margin-bottom: 0;
        }
        .promo_price {
            font-size: ${Fonts.sizes.xs};
        }
        .price {
            font-size: ${Fonts.sizes.xs2};
            letter-spacing: 0;
        }
        .price_no_promo {
            font-size: ${Fonts.sizes.xs2};
            letter-spacing: 0;
        }
        .price::after {
            right:0px;
        }
    }
    @media screen and (max-width: 480px) {
        .card {
            width: 100%;
            height: auto;
            margin-right: 10px;
            margin-bottom: 0px;
        }
        .price_percentage {
            display: block;
        }
        .name {
            height: 50px;
            line-height: 22px;
        }
        .description {
            font-size: ${Fonts.sizes.xs};
            text-align: justify;
        }
    }
    @media screen and (max-width: 414px) {
        .name {
            width: 100px;
        }
    }
`;

const CardBody = styled.div`
    padding: 0 26px;
    @media screen and (max-width: 480px) {
        padding: 0 15px;
    }
`

const CardImage = styled.div`
    width: 158px;
    height: 158px;
    margin: 10px auto 20px auto;
    @media screen and (max-width: 600px) {
        width: 100px;
        height: auto;
        text-align: center;
        // margin: auto;
    }
`

const Row = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1.23rem;

    @media screen and (min-width: 1025px) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        padding-bottom: 50px;
    }
    @media screen and (max-width: 320px) {
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 50px;
        grid-gap: 1rem;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 35px;
    }
`
const PackStyling = styled.div`
    display: flex;
    flexDirection: row;
    margin-bottom: 20px;
    align-items: center;
    p {
        color: rgba(154, 154, 154, 0.9);
        font-size: 14px;
        margin: 0;
    }
    .slash {
        margin-left: 5px;
    }
    .pack {
        margin-left: 2px;
        margin-top: 3px;
        font-family: ${Fonts.avenirRoman}
    }
    @media screen and (max-width: 480px) {
        p {
            font-size: ${Fonts.sizes.xss};
        }
        .pack {
            margin-top: 0px;
        }
    }
`

const PercentageStyling = styled.div`
    display: flex;
    flexDirection: row;
    align-items: center;
    p {
        margin: 0;
    }
`


export { Container, Row, CardBody, PackStyling, CardImage, PercentageStyling };
