import Styled from 'styled-components';
import { Colors, Fonts } from 'constant';
import styled from 'styled-components';

interface Props {
  isNotEmpty?: boolean;
}

const Styling = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  .navbar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .img-logo {
    width: 176px;
    height: 30px;
  }

  .img-logo:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    .img-logo {
      width: 121px;
      height: auto;
      margin: auto;
    }
  }
  `;
const FooterRight = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .logo_socmed_footer {
      width: 15px;
      height: 15px;
    }
`;
const Title = Styled.span`
    font-size: 16px;
    color: ${ Colors.black.lighter };
    letter-spacing: 5px; 
    padding: 0px 20px;
    @media screen and (max-width: 800px) {
      display: none;
  }
`;

const Line = Styled.div`
  width: 40px;
  border-top: 1px solid ${ Colors.black.lighter };
  @media screen and (max-width: 800px) {
    display: none;
}
`;

const List = Styled.div`
  ul {
    list-style: none;
    padding: 0px 20px;
    margin: auto;
    li {
      display:inline;
      color: red;
      text-align: center;
      padding: 16px 20px;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 800px) {
    display: none;
}
`;

const FooterStyling = styled.div`
  padding: 20px 85px;
  background-color: #FAFAFA;
  .copyright {
    margin: 20px 0 0px;
    color: ${ Colors.black.lighter };
    font-size: ${ Fonts.sizes.xs };
    font-family: ${ Fonts.avenirHeavy }
  }
  .companyName {
    margin: 0px 0 0px;
    color: ${ Colors.black.lighter };
    font-size: ${ Fonts.sizes.xs };
    font-family: ${ Fonts.avenirHeavy }
  }
  .companyTag {
    margin: 0px 0 0px;
    color: ${ Colors.black.lighter };
    font-size: ${ Fonts.sizes.xs };
    font-family: ${ Fonts.avenirHeavy };
  }
  @media screen and (max-width: 480px) {
    .copyright {
    margin: 10px 0 0px;
    font-size: ${ Fonts.sizes.xs2 };
    text-align: center;
    }
    .companyName {
      margin: 0px 0 0px;
      font-size: ${ Fonts.sizes.xs2 };
      text-align: center;
    }
    .companyTag {
      margin: 0px 0 0px;
      font-size: ${ Fonts.sizes.xs2 };
      text-align: center;
      }
    display: ${ (props: Props) => props.isNotEmpty ? 'none' : '' };
  }
  left: 0;
  bottom: 0;
  width: 100%;
`;

export { Styling, Title, List, Line, FooterRight, FooterStyling };
