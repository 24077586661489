import Styled from 'styled-components';
import { Colors, Fonts } from 'constant';
import { Images } from 'constant';
import ArrowDown from '../../assets/images/arrow@3x.png';

const SelectOptWrapper = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    @media screen and (max-width: 800px) {
      width: 50%;
    }
`

const SelectOpt = Styled.select`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 150px;
    height: 40px;
    background: transparent;
    background-image: url(${ArrowDown});
    background-size: 15px 10px;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
    color: #515151;
    font-family: ${Fonts.avenir};
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    font-weight: 600;
    outline: none;
    @media screen and (max-width: 800px) {
      width: 70%;
      font-size: 12px;
      background-size: 10px 5px;
    }
`

const OptionItem = Styled.option`
    @media screen and (max-width: 800px) {
        font-size: 10px;
    }
`

const LocationLabel = Styled.label`
  color: #9A9A9A;
  font-family: ${Fonts.avenir};
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 500;
  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
`

const LocationWrapper = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 350px;
    @media screen and (max-width: 800px) {
      display: flex;
      width: 70%;
    }
`

export { SelectOpt, LocationLabel, LocationWrapper, OptionItem, SelectOptWrapper };