import React from 'react';
import { Provider } from "react-redux";

import Router from 'router';
import { history, store } from 'store';
import 'antd/dist/antd.css';

const App = ( props: any ) => {
  return (
    <Provider store={ store }>
      <Router history={ history } />
    </Provider>
  );
};
export default App;