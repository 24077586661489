import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Styling, Title, List, Line, NavbarRight, NavbarLeft, PinLocation } from "./style";
import { Image } from "components";
import { Images } from "constant";
import SelectOption from "components/SelectOption";
import {
  getBannerAction,
} from "store/banners/actions";
import { onGetBranch, setBranchID } from "store/branches/actions";
import { RouteComponentProps, useHistory } from "react-router";
import { RootState } from "interfaces/common";
import ICLocationIcon from '../../assets/images/iclocation@3x.png'
import { objectToQuery, queryToString } from "helpers";
import { onGetBannerDetailAction } from "store/banners/actions";
import { onGetProduct } from "store/product/actions";


export const Navbar = (props : NavbarProps) => {
  const {
    branch,
    onGetBannerDetailAction,
    onGetProduct,
  } = props

  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedBranch, setSelectedBranch] = useState('1')

  const linkParams = queryToString(window.location.search)

  const onLocationSelected = (e: React.ChangeEvent<HTMLSelectElement>):void => {
    console.log(linkParams, objectToQuery(linkParams));

    let code_data = linkParams.code_data as string
    
    if (linkParams.code_link && linkParams.code_link === "1") {
      linkParams.branch_id = e.target.value
      props.history.push(`link?${objectToQuery(linkParams)}`)
      onGetBannerDetailAction({
        id: atob(code_data),
        branch_id: e.target.value
      });
      dispatch(setBranchID(e.target.value))
    } else if (linkParams.code_link && linkParams.code_link === "2") {
      linkParams.branch_id = e.target.value
      props.history.push(`link?${objectToQuery(linkParams)}`)
      let payload = {
        params: {
          product_code: atob(code_data),
          branch_id: e.target.value
        },
      };
      onGetProduct(payload);
      dispatch(setBranchID(linkParams.branch_id))
    } else {
      console.log('masuk else');
      props.history.push("/");
      let payload = {
        params: {
          branch_id: e.target.value
        }
      }

      dispatch(getBannerAction(payload))
      dispatch(setBranchID(e.target.value))
    }
  }

  const onClickLogo = () => {
    history.push('/')
  }

  useEffect(() => {
    dispatch(onGetBranch())
  }, [])

  useEffect(() => {
    if(linkParams.branch_id){
      if(branch){
        branch.list.data?.map(city => {
          if(linkParams.branch_id === city.id.toString()){
            setSelectedBranch(linkParams.branch_id)
          }
        })
      }
    }
  }, [branch])
  
  return (
    <Styling>
      <NavbarLeft>
        <Image onClick={onClickLogo} src={Images.logo?.default} className="img-logo" />
        {
          branch.list.data && branch.list.data.length && 
          <SelectOption 
            label="Lokasi Anda"
            onChange={onLocationSelected}
            data={branch.list.data}
            children={<PinLocation src={ICLocationIcon} />}
            selectedOpt={selectedBranch}
          />

        }
      </NavbarLeft>
      <NavbarRight>
        <Title>FOLLOW</Title>
        <Line />
        <List>
          <ul>
            <li>
              <a href="http://bit.ly/FBFreshBox">
                <Image src={Images.facebook_logo?.default} />
              </a>
            </li>
            <li>
              <a href="http://bit.ly/InstagramFreshBox">
                <Image src={Images.instagram_logo?.default} />
              </a>
            </li>
          </ul>
        </List>
      </NavbarRight>
    </Styling>
  );
};

interface EventDispatch {
  onGetBannerDetailAction: Function;
  onGetProduct: Function;
}

const mapDispatchToProps: EventDispatch = {
  onGetBannerDetailAction,
  onGetProduct
};

const mapStateToProps = (state: RootState) => ({
  product: state.product,
  product_banner: state.product.product_banner,
  banners: state.banners.banners,
  banner_detail: state.banner_detail.banner_detail,
  branch: state.branch
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type EnhancedProps = StateProps & DispatchProps;

type NavbarProps = OwnProps & EnhancedProps & RouteComponentProps;


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
