import { hasObjectValue } from 'helpers';
import { BannerDetail } from 'interfaces/banners/banner-detail';
import { useEffect } from 'react';
import { useState, useRef } from 'react'
interface ProductProps {
    data: BannerDetail;
    getProduct: (params: any) => void;
    bannerId?: number;
    type?: number;
    onSearchProduct: any
}

const useProduct = ({ getProduct, data, bannerId, type, onSearchProduct }: ProductProps) => {

    const usePrevious = <T extends unknown>(value: T): T | undefined => {
        const ref = useRef<T>();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };
    const [btnActive, setBtnActive] = useState<number>(0);
    const [text, setText] = useState<string>("");
    const [init, setInit] = useState<boolean>(true);
    const prevBannerId = usePrevious(bannerId);

    useEffect(() => {
        setInit(false)
        if (prevBannerId !== bannerId) {
            onChangeText("");
            setButton(0);
        }
    }, [bannerId]);

    useEffect(() => {
        if (!init) {
            onPressCategory("");
        }
    }, [text]);

    const onChangeText = (e: any) => {
        setText(e);
    };

    const onPressCategory = (value: string) => {
        if (type !== 2 || type === undefined) {
            const obj = hasObjectValue(data, 'new_products') && Object.keys(data.new_products)
            let payload;
            if (obj) {
                if (value !== "Semua Produk Promo" && value !== "") {
                    payload = {
                        category_code: data.new_products[value].info.category_code,
                        product_detail_type: data.new_products[value].info.product_detail_type,
                        banner_id: data.new_products[value].info.banner_id,
                        name: text !== "" ? text : null,
                        type: data.type
                    };
                } else {
                    if (value !== "Semua Produk Promo" && btnActive !== 0) {
                        payload = {
                            category_code:
                                data.new_products[obj[btnActive - 1]].info.category_code,
                            product_detail_type:
                                data.new_products[obj[btnActive - 1]].info.product_detail_type,
                            banner_id: data.new_products[obj[btnActive - 1]].info.banner_id,
                            name: text !== "" ? text : null,
                            type: data.type
                        };

                    } else {
                        payload = {
                            banner_id: bannerId,
                            name: text !== "" ? text : null,
                            type: data.type
                        };
                    }
                }
            } else {
                payload = {
                    banner_id: bannerId,
                    name: text !== "" ? text : null,
                };
            }
            getProduct(payload);
        } else if (type === 2) {
            onSearchProduct(text)
        }
    };

    const renderCategory = () => {
        if (hasObjectValue(data, "new_products")) {
            let elemnt = ["Semua Produk Promo"];
            for (const obj in data.new_products) {
                if (obj !== "info") {
                    elemnt.push(obj);
                }
            }
            return elemnt
        }
    }

    const setButton = (index: number) => {
        setBtnActive(index)
    }


    return { text, onChangeText, onPressCategory, renderCategory, setButton, btnActive, usePrevious }
}



export default useProduct