import { Common, Banners } from 'interfaces'
import * as BannerServices from 'services/banners/banners'
import { BannerActionTypes } from './action-types'

export const getBannersSuccess = (payload: { data: Banners.BannerIntialState }) => ({
    type: BannerActionTypes.GET_BANNER_LIST_SUCCESS,
    payload,
})

export const getBannerPending = () => ({
    type: BannerActionTypes.GET_BANNER_LIST_PENDING,
});

export const getBannerAction = (params: any) => (dispatch: (arg0: any) => void) => {
    return new Promise<void>(async (resolve, reject) => {
        dispatch(getBannerPending())
        const result: Common.ApiResponseData<Banners.BannerIntialState> = await BannerServices.getBanners(params);

        const { code, data, code_message, code_type, pagination } = result
        
        if (code === 200) {
            dispatch(getBannersSuccess({ data }))
            resolve()
        } else {
            reject(code_message)
        }
    })
}

export const onGetBannerDetailSuccess = (payload: { data: Banners.BannerIntialState }) => ({
    type: BannerActionTypes.GET_BANNER_DETAIL_SUCCESS,
    payload,
})

export const getBannerDetailPending = () => ({
    type: BannerActionTypes.GET_BANNER_DETAIL_PENDING,
});

export const onGetBannerDetailAction = (params: Common.ApiParams) => (dispatch: (arg0: any) => void) => {
    return new Promise<void>(async (resolve, reject) => {
        dispatch(getBannerDetailPending())
        const result: Common.ApiResponseData<Banners.BannerIntialState> = await BannerServices.getBannerDetail(params);
        const { code, data, code_message } = result
        if (code === 200) {
            dispatch(onGetBannerDetailSuccess({ data }))
            resolve()
        } else {
            reject(code_message)
        }
    })
}
