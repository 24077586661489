import Config from './Config';

export default {
  url: Config.url,
  // eslint-disable-next-line no-undef
  login: '/auth/admin-login',
  product: '/v1/product',
  banner: '/v1/banners/',
  branch: '/v1/branch/',
  city: (payload: any) => (`city/${payload.parentId}`),
};
