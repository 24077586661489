import React from "react";
import { BannerWrapper, BannerInfo, Relative, CenteredStyling } from "./style";
import { Banner, Image, Button, Text } from "components";
import { Images } from "constant";
import { openApp } from "helpers";

const BannerDownload = () => {
  const redirectToApp = (device: string) => {
    openApp(device, 0);
  };
  return (
    <Banner className="banner">
      <Relative>
        <Image
          src={Images.img_vegetable_left?.default}
          className="img_vegetable_left"
        />
      </Relative>
      <CenteredStyling>
        <Relative>
          <div className="relative">
            <Image
              src={Images.screen_android?.default}
              className="img-phone android"
            />
            <Image
              src={Images.screen_iphonex?.default}
              className="img-phone ios"
            />
          </div>
        </Relative>
        <BannerInfo>
          <Text className="download" text="Download FreshBox App Now!" />
          <Text
            className="info"
            text="Have the best produce conveniently delivered to your door."
          />
        </BannerInfo>
        <BannerWrapper className="mr-5">
          <Button
            className="button-margin"
            onClick={() => redirectToApp("ios")}
          >
            <div className="button-wrapper">
              <div>
                <Image src={Images.icon_apple?.default} className="img-icon" />
              </div>
              <div>
                <Text className="ios-available" text="Available On The" />
                <Text className="ios-appstore" text="App Store" />
              </div>
            </div>
          </Button>
          <Button
            className="button-margin"
            onClick={() => redirectToApp("android")}
          >
            <div className="button-wrapper">
              <div>
                <Image src={Images.icon_google?.default} className="img-icon" />
              </div>
              <div>
                <Text className="android" text="Google Play" />
              </div>
            </div>
          </Button>
        </BannerWrapper>
      </CenteredStyling>
      <Relative>
        <Image
          src={Images.img_vegetable_right?.default}
          className="img_vegetable_right"
        />
      </Relative>
    </Banner>
  );
};

export default BannerDownload;
