export default {
  muliBold: 'Muli Bold',
  muliSemiBold: 'Muli Semi Bold',
  muliMedium: 'Muli Medium',
  muliBolder: 'Muli Bolder',
  avenirHeavy: 'Avenir-Heavy',
  avenirMedium: 'Avenir-Medium',
  avenirRoman: 'Avenir-Roman',
  avenirLight: 'Avenir-Light',
  avenir: 'Avenir',
  helvetica: 'Helvetica Neue',
  helveticaMedium: 'helveticaneue-medium',
  robotoMed: 'Roboto-Medium',
  robotoRegular: 'Roboto-Regular',
  sizes: {
    xl: "56px",
    l: "48px",
    l2: "40px",
    m4: "38px",
    m3: "32px",
    m2: "28px",
    m1: "24px",
    ms: "22px",
    m: "20px",
    s2: "18px",
    s: "16px",
    xs: "14px",
    xs2: "12px",
    xss: "11px"
  },
};
