import { compose, applyMiddleware, createStore, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from "connected-react-router";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { History as history } from "helpers";

import productReducer from './product/reducers';
import bannerReducer from './banners/reducers'
import branchReducer from './branches/reducers';
const routeMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({
	product: productReducer,
	banners: bannerReducer.bannerReducer,
	banner_detail: bannerReducer.bannerDetailReducer,
	branch: branchReducer,
	router: connectRouter(history),

});

// const store = createStore(rootReducer, compose(applyMiddleware(thunk, routeMiddleware)));
const store = createStore(rootReducer, compose(composeWithDevTools(applyMiddleware(thunk, routeMiddleware))));

export { history, store };
