import React from "react";

interface TextInputProps {
  className?: string;
  type?: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  value?: string;
}

const TextInput = ({
  className = "",
  type = "",
  onChange,
  placeholder,
  value,
  ...props
}: TextInputProps) => {
  return (
    <input
      type={type}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};

export default TextInput;
