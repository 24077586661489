import React from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Dashboard, Links } from "views";
import { history } from "store";
import { GlobalStyles } from "constant";
import { Navbar, Footer } from "components";

const routes = [
  {
    exact: true,
    path: "/",
    component: Dashboard,
  },
  {
    exact: true,
    path: "/catalog",
    component: Dashboard,
  },
  {
    exact: true,
    path: "/link",
    component: Links,
  },
  {
    exact: false,
    path: "/400",
    component: Dashboard,
  },
  {
    exact: false,
    path: "/403",
    component: Dashboard,
  },
  {
    exact: false,
    path: "/404",
    component: Dashboard,
  },
  {
    exact: false,
    path: "/503",
    component: Dashboard,
  },
];

const Router = (props: any) => {
  return (
    <ConnectedRouter history={ history }>
      <GlobalStyles />
      <Navbar { ...props } />
      <Switch>
        { routes.map((route) => {
          const { path, component, exact } = route;
          return (
            <Route exact={ exact } key={ path } path={ path } component={ component } />
          );
        }) }
      </Switch>
      {/* <Footer {...props} /> */ }
    </ConnectedRouter>
  );
};

export default Router;
