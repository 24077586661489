import React from "react";
import { connect } from "react-redux";
import {
  Styling,
  Title,
  List,
  Line,
  FooterRight,
  FooterStyling,
} from "./style";
import { RouteComponentProps } from "react-router-dom";
import { Image, Text } from "components";
import { Images } from "constant";
import { RootState } from "interfaces/common";
import { hasObjectValue } from "helpers";

export const Footer = ({ product }: FooterProps) => {
  return (
    <FooterStyling isNotEmpty={ hasObjectValue(product, "data") }>
      <Styling>
        <Image src={ Images.logo?.default } className="img-logo" />
        <FooterRight>
          <Title>FOLLOW</Title>
          <Line />
          <List>
            <ul>
              <li>
                <a href="http://bit.ly/FBFreshBox">
                  <Image
                    src={ Images.facebook_logo?.default }
                    className="logo_socmed_footer"
                  />
                </a>
              </li>
              <li>
                <a href="http://bit.ly/InstagramFreshBox">
                  <Image
                    src={ Images.instagram_logo?.default }
                    className="logo_socmed_footer"
                  />
                </a>
              </li>
            </ul>
          </List>
        </FooterRight>
      </Styling>
      <Text text="© 2020 FreshBox. All Rights Reserved" className="copyright" />
      <Text text="PT Berkah Tani Sejahtera" className="companyName" />
      <Text text="From our farm to your table" className="companyTag" />
    </FooterStyling>
  );
};

const mapStateToProps = (state: RootState) => ({
  product: state.product.list,
});

const mapDispatchToProps = {};
type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type EnhancedProps = StateProps & DispatchProps;

type FooterProps = OwnProps & EnhancedProps & RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
