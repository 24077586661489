import styled from "styled-components";
import { Colors, Fonts } from 'constant';


const Container = styled.div`
    width: 100%;
    margin-bottom: 3rem;

    .load {
        width: 100%;
        height: 100%;
        background: white;
        position: absolute;
        z-index: 99;
        top: 0;
        left:0;
        text-align: center;
    }

    .logo_loading {
        position: relative;
        top: 40%;
        max-width: 100%;
    }

    .waiting {
        font-size: 44px;
        font-family: ${Fonts.avenirMedium};
        color: rgba(81, 81, 81, 0.9);
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 1.2;
    }
    .grocery_shopping {
        font-size: 30px;
        font-family: ${Fonts.avenirRoman};
        color: rgba(81, 81, 81, 0.9);
        margin-bottom: 4rem;
    }
    .click_button {
        font-family: ${Fonts.avenirRoman};
        font-size: 20px;
        font-weight: 500;
        color: rgba(81, 81, 81, 0.9);
    }

    .btn_launch {
        width: 220px;
        background-color: ${Colors.red.custom};
        text-align: center;
        padding: 12px 0;
        background-image: linear-gradient(90deg, #D9003E 0%, #F90040 100%);
        border-radius: 8px;
        box-shadow: 5px 8px 10px 0 rgba(229, 37, 70, 0.5);
        margin: auto;
        p {
            color: ${Colors.white.default};
            margin: auto;
            font-size: ${Fonts.sizes.s2};
            font-family: ${Fonts.avenirHeavy};
        }

        :hover {background-color: #3e8e41}

        :active {
          background-color: #3e8e41;
          box-shadow: 5px 8px 10px 0 rgba(229, 37, 70, 0.5);
          transform: translateY(4px);
        }
    }

    @media screen and (max-width: 480px) {
        margin-top: 1rem;
        .grocery_shopping {
            margin-bottom: 1rem;
            font-size: 26px;
        }
        .waiting {
            margin-bottom: 20px;
        }
        .btn_launch {
            position: fixed;
            width: 100%;
            bottom: 0;
            left:0;
            border-radius: 0;
        }
    }
`;


export { Container };
