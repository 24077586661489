import { Endpoints } from "constant"
import { customFetch } from "helpers"

export const getBranches = async () => {
    try {
        const response = await customFetch(`${Endpoints.url}${Endpoints.branch}`, 'get');
        const res = await response.json();
        return res;
    } catch (error) {
        throw error
    }
}