import { useState, useRef, useEffect } from 'react'

type UseProductProps = {
  sliderRef: any
}

const useProductDetail = (props: UseProductProps) => {
  const { sliderRef } = props
  const [imgActive, setImgActive] = useState(0)

  const afteChange = (e: number) => {
    setImgActive(e);
  };

  const slickMove = (type: string) => {
    if (sliderRef.current) {
      if (type === "next") {
        sliderRef.current?.next();
      } else {
        sliderRef.current?.prev();
      }
    }
  };

  const goToSlide = (slide: number) => {
    setImgActive(slide)
    sliderRef.current?.goTo(slide)
  }

  return {
    imgActive,
    sliderRef,
    slickMove,
    afteChange,
    goToSlide
  }

}
export default useProductDetail