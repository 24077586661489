import styled from "styled-components";
import { Colors, Fonts } from 'constant';

const Relative = styled.div`
    position: relative;

    .relative {
        position: relative;
        height: 190px;
        overflow: hidden;
        width: 330px;
        @media screen and (max-width: 800px) {
            display: none;
        }
    }
    .relative > .img-phone {
        position: absolute;
        transform: rotate(-10deg);
    }

    .img_vegetable_left {
        width: 80px;
        height: 80px;
        position: absolute;
    }
    .img_vegetable_right {
        width: 120px;
        height: 120px;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .img-phone {
        width: 183px;
    }
    .android {
        top: 30px;
        left: 60px;
    }
    .ios {
        left: 145px;
    }

    @media screen and (max-width: 480px) {
        .img_vegetable_right {
            display: none;
        }
        .img_vegetable_left {
            width: 45px;
            height: 45px;
        }
    }
`

const CenteredStyling = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .mr-5 {
        margin-right: 6rem;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        padding-bottom: 10px;
        .mr-5 {
            margin-right: 0;
        }
    }
`

const BannerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    .to-absolute img {
        top: 20px;
        position: relative;
    }


    .button-wrapper {
        width: 160px;
        margin: 0px 5px;
        display:flex;
        align-items: center;
        height: 62px;
        justify-content: center;
        p {
            margin-bottom: 0;
            color: ${Colors.white.default};
            margin-left: 10px;
        }
        .ios-available {
            font-size: 14px;
            font-weight: 100;
            line-height: 13px;
            font-family: ${Fonts.helvetica};
        }
        .ios-appstore {
            font-size: 18px;
            font-family: ${Fonts.helvetica};
            line-height: 25px;
        }
        .android {
            font-size: 18px;
            font-family: ${Fonts.robotoRegular};
        }

        @media screen and (max-width: 480px) {
            width: 158px;
            height: 44px;
            margin: 0px 0px;
            .ios-available {
                font-size: ${Fonts.sizes.xs2};
                font-weight: 100;
                line-height: 12px;
            }
            .ios-appstore {
                font-size: ${Fonts.sizes.s};
                line-height: 23px;
            }
            .android {
                font-size: ${Fonts.sizes.s};
            }
        }
    }

    .img-icon {
        width: 23px;
        height: 28px;
    }
`

const BannerInfo = styled.div`
    margin-top: 20px;
    .download {
        font-family: ${Fonts.avenir};
        font-weight: 500;
        color: ${Colors.white.default};
        line-height: 0;
        font-size: ${Fonts.sizes.m2};
        @media screen and (max-width: 1231px) {
            line-height: 32px;
            margin-bottom: 0;
        }
    }
    .info {
        color: ${Colors.white.default};
        font-family: ${Fonts.avenir};
        line-height: 30px;
        font-size: ${Fonts.sizes.m};
        width: 350px;
        margin: 0;
        letter-spacing: 0.01em;
    }
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
        .download {
            font-size: ${Fonts.sizes.m};
            margin: 0;
            line-height: 29px;
        }
        .info {
            font-size: ${Fonts.sizes.xs};
            line-height: 25px;
            width: 310px;
        }
    }
    
`


export { BannerWrapper, BannerInfo, Relative, CenteredStyling };
