export const hasObjectValue = (obj: any, key: string) => {
    // eslint-disable-next-line
    return key.split('.').every((x: any) => {
        if (typeof obj !== 'object' || obj === null || !(x in obj)) return false;
        // eslint-disable-next-line
        obj = obj[x];
        return true;
    });
};

// eslint-disable-next-line
export const hasValue = (val: any, key: string) => {
    return key in val;
};

export default hasObjectValue
