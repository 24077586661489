import Styled from 'styled-components';
import { Colors } from 'constant';

const Styling = Styled.div`
    padding: 20px 85px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  .navbar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .img-logo {
    width: 236px;
    height: 40px;
  }

  .img-logo:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    padding: 30px 0;
    justify-content: center;
    .img-logo {
      width: 141px;
      height: 24px;
    }
}
  `;

const NavbarLeft = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  @media screen and (max-width: 800px) {
    display: flex;
  }
`
const NavbarRight = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
      display: none;
  }
`
const Title = Styled.span`
    font-size: 18px;
    color: ${Colors.black.lighter};
    letter-spacing: 5px; 
    padding: 0px 20px;
    @media screen and (max-width: 800px) {
      display: none;
  }
`;

const Line = Styled.div`
  width: 40px;
  border-top: 1px solid ${Colors.black.lighter};
  @media screen and (max-width: 800px) {
    display: none;
}
`

const List = Styled.div`
  ul {
    list-style: none;
    padding: 0px 20px;
    margin: auto;
    li {
      display:inline;
      color: red;
      text-align: center;
      padding: 16px 20px;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 800px) {
    display: none;
}
`

const PinLocation = Styled.img`
    width: 8%;
    height: 8%;
    // position: absolute;
`


export { Styling, Title, List, Line, NavbarRight, NavbarLeft, PinLocation };
