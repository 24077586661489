import { Banners } from 'interfaces'
import { BannerActionTypes } from './action-types'
import { initialState } from 'interfaces/banners/_index'
import * as actions from './actions'
import { hasObjectValue } from 'helpers/has-value'

const setBanners = (state: Banners.BannerIntialState, action: ReturnType<typeof actions.getBannersSuccess>) => ({
    banners: {
        ...state.banners,
        ...action.payload.data,
        loading: false
    }
})

const setPending = (state: Banners.BannerIntialState, action: ReturnType<typeof actions.getBannerPending>) => ({
    banners: {
        ...state.banners,
        loading: true
    }
});

const bannerReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case BannerActionTypes.GET_BANNER_LIST_SUCCESS:
            return setBanners(state, action);
        case BannerActionTypes.GET_BANNER_LIST_PENDING:
            return setPending(state, action);
        default:
            return state;
    }
};

const setBannerDetailPending = (state: Banners.BannerIntialState, action: ReturnType<typeof actions.getBannerDetailPending>) => ({
    banner_detail: {
        ...state.banner_detail,
        loading: true
    }
});

const setBannerDetail = (state: Banners.BannerIntialState, action: ReturnType<typeof actions.onGetBannerDetailSuccess>) => {
    const new_product = hasObjectValue(action.payload.data, 'new_products')
    if (new_product) {
        return {
            banner_detail: {
                ...state.banner_detail,
                ...action.payload.data,
                loading: false
                // ...new_product
            }
        }
    } else {
        return {
            banner_detail: {
                ...action.payload.data,
                loading: false
                // ...new_product
            }
        }
    }
}

const bannerDetailReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case BannerActionTypes.GET_BANNER_DETAIL_SUCCESS:
            return setBannerDetail(state, action);
        case BannerActionTypes.GET_BANNER_DETAIL_PENDING:
            return setBannerDetailPending(state, action)
        default:
            return state;
    }
};


const BannerReducer = {
    bannerReducer,
    bannerDetailReducer,
}

export default BannerReducer;