import React from "react";
import { StyledText } from "./style";

const Text: React.FC<TextProps> = ({ color, text, className, ...props }) => {
  return (
    <StyledText color={color} className={className} {...props}>
      {text}
    </StyledText>
  );
};

type TextProps = {
  text?: string;
  color?: string;
  className?: string;
};

export default Text;
