export enum BannerActionTypes {
    // Product
    GET_BANNER_LIST = 'GET_BANNER_LIST',
    GET_BANNER_LIST_SUCCESS = 'GET_BANNER_LIST_SUCCESS',
    GET_BANNER_LIST_PENDING = 'GET_BANNER_LIST_PENDING',

    BANNER_ERROR = 'BANNER_ERROR',

    GET_BANNER_DETAIL = 'GET_BANNER_DETAIL',
    GET_BANNER_DETAIL_SUCCESS = 'GET_BANNER_DETAIL_SUCCESS',
    GET_BANNER_DETAIL_PENDING = 'GET_BANNER_DETAIL_PENDING',

    GET_PRODUCT_OF_BANNER = 'GET_PRODUCT_OF_BANNER',
    GET_PRODUCT_OF_BANNER_SUCCESS = 'GET_PRODUCT_OF_BANNER_SUCCESS',

    BANNER_DETAIL_ERROR = 'BANNER_ERROR'
}