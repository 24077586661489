import Styled, { keyframes, } from 'styled-components';

interface Props {
  width?: string
  marginTop?: string
  height?: string
  marginBottom?: string
}

const ShimmerKeyframe = keyframes`
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
`;

const Container = Styled.div`
div {
  display: inline-flex;
  flex-direction: column;
  // margin-left: 25px;
  vertical-align: top;
}
  .shimmer_large {
    background: #f6f7f8;
    background-image: linear-gradient(to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 477px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: ${ShimmerKeyframe};
    -webkit-animation-timing-function: linear;
    // margin-right: 30px;
    .box-full {
      width: 100vw;
      height: 500px;
    }
  }
  .shimmer_medium {
		background: #f6f7f8;
		background-image: linear-gradient(to right,
				#f6f7f8 0%,
				#edeef1 20%,
				#f6f7f8 40%,
				#f6f7f8 100%);
		background-repeat: no-repeat;
		background-size: 800px 104px;
		display: inline-block;
		position: relative;

		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: forwards;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-name: ${ShimmerKeyframe};
		-webkit-animation-timing-function: linear;
  }

`

const Shine = Styled.div`
    background: #f6f7f8;
    background-image: linear-gradient(to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 477px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: ${ShimmerKeyframe};
    -webkit-animation-timing-function: linear;
    margin-right: 10px;
    height:${(x: Props) => x.height ? x.height : "20px"};
    margin-top: ${(x: Props) => x.marginTop ? x.marginTop : "10px"};
    margin-bottom: ${(x: Props) => x.marginBottom ? x.marginBottom : "0"};
    .box-full {
      width: 100vw;
      height: 500px;
    }
`

const Animate = Styled.div`
  margin-top: ${(x: Props) => x.marginTop ? x.marginTop : "10px"};
`;

const Card = Styled.div`
    border-radius: 10px;
    width: 200px;
    height: 333px;
    // margin-right: 30px;
    margin-bottom: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
`
const BoxPhoto = Styled.div`
  border-radius: 10px;
  width: ${(p: Props) => p.width ? p.width : "170px"};
  height: 100px;
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  @media screen and (max-width: 600px) {
    // margin-right: 10px;
}
`

const Lines = Styled.div`
  height: 5px;
  width: ${(p: Props) => p.width ? p.width : "200px"};
  
`
const BannerShine = Styled.div`
    width: 800px;
    height: 477px;
    background: #f6f7f8;
    background-image: linear-gradient(to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%);
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: ${ShimmerKeyframe};
    -webkit-animation-timing-function: linear;
    // margin-right: 30px;
    height:${(x: Props) => x.height ? x.height : "20px"};
    margin-top: ${(x: Props) => x.marginTop ? x.marginTop : "10px"};
    margin-bottom: ${(x: Props) => x.marginBottom ? x.marginBottom : "0"};
    .box-full {
      width: 100vw;
      height: 500px;
    }
    .slick-list .slick-slide .slick-active {
      transform: scale(1.25);
      transition: transform 0.8s;
  }
`


export { Container, Animate, ShimmerKeyframe, Card, BoxPhoto, Lines, Shine, BannerShine }
