export enum ProductActionTypes {
  // Product
  GET_PRODUCT_LIST = 'GET_PRODUCT_LIST',
  GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS',
  GET_PRODUCT_LIST_PENDING = 'GET_PRODUCT_LIST_PENDING',
  GET_PRODUCT_LIST_REJECT = 'GET_PRODUCT_LIST_REJECT',
  GET_PRODUCT_LIST_BANNER_SUCCESS = 'GET_PRODUCT_LIST_BANNER_SUCCESS',

  SEARCH_PRODUCT_SUCCESS = "SEARCH_PRODUCT_SUCCESS",

  PRODUCT_ERROR = 'PRODUCT_ERROR',

  GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS",
  GET_PRODUCT_DETAIL_PENDING = "GET_PRODUCT_DETAIL_PENDING",
  GET_PRODUCT_DETAIL_REJECT = "GET_PRODUCT_DETAIL_REJECT",
}