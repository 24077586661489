import Styled from 'styled-components';
import { Colors } from 'constant';

const { black } = Colors;

interface Props {
  background?: string;
}

const StyledButton = Styled.div`
  background: ${(props: Props) => (props.background ? props.background : black.default)};
  :hover {
    cursor: pointer;
  }
`;

export { StyledButton };
