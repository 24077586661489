import React from "react";
import { SelectOpt, LocationLabel, LocationWrapper, OptionItem, SelectOptWrapper } from './style'
import { BranchData } from '../../interfaces/branch'

interface SelectOptionProps {
  label?: string;
  data?: BranchData[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  children?: React.ReactNode;
  selectedOpt?: string;
}

const SelectOption = (selectOption: SelectOptionProps) => {
  const {label, onChange, data, children, selectedOpt} = selectOption
  
  return (
    <LocationWrapper>
        <LocationLabel>{label}</LocationLabel>
        <SelectOptWrapper>
            {children}
            <SelectOpt onChange={e => onChange(e)}>
              {
                data?.map((child, i) => (
                  <OptionItem 
                    key={child.id} 
                    value={child.id}
                    selected={selectedOpt === child.id.toString() ? true : false}
                  >
                    {child.name}
                  </OptionItem>
                ))
              }
            </SelectOpt>
        </SelectOptWrapper>
    </LocationWrapper>
  );
};


export default SelectOption;