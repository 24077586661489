import styled from "styled-components";
import { Colors } from 'constant';


const Container = styled.div`
    margin: 2rem 0 0 0;
    position: relative;
    .slick-track {
        align-items: center;
        display: flex;
    }

    .slick-slider {
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
      }

    .slick-slide.slick-center img {
        transform: scale(1.20);
        transition: transform 0.8s;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
      touch-action: pan-y;
    }

    .slick-slide.slick-center .box_shimmer {
        transform: scale(1.25);
        transition: transform 0.8s;
    }

    .ant-carousel .slick-slide .box_shimmer {
        margin: 0 60px;
        @media screen and (max-width: 1024px) {
            margin: 0 30px;
        }
        @media screen and (max-width: 480px) {
            margin: 0 ;
        }
    }

    .ant-carousel .slick-slide img {
        margin: auto;
        margin-bottom: 25px !important;
        @media screen and (min-width: 1534px) {
            width: 50vw;
        }
        @media screen and (max-width: 800px) {
            margin-bottom: 0 !important;
        }
    }

    .ant-carousel .slick-list .slick-slide.slick-active {
        padding: 30px 0;
        @media screen and (max-width: 800px) {
            padding: 0;
        }
    }
    .ant-carousel .slick-dots li button {
        width: 10px !important;
        height: 10px !important;
        border-radius: 100%;
    }
    .ant-carousel .slick-dots li.slick-active button {
        background: ${Colors.red.custom}
    }

    .img_carousel {
        width: 600px;
        height: 300px;
        border-radius: 10px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    }

    .box_shimmer {
        width: 600px;
        height: 300px;
        border-radius: 10px;
    }

    @media screen and (max-width: 800px) {
        padding: 0;
        .img_carousel {
            width: 100%;
            height: auto;
            border-radius: 0px;
            border-radius: 0;
            box-shadow: none;
        }
        .box_shimmer {
            width: 100%;
            height: 300px;
            border-radius: 0px;
        }
    }

    @media screen and (max-width: 800px) {
        margin : 0;
    }

    @media screen and (max-width: 480px) {
        .box_shimmer {
            width: 100%;
            height: 180px;
            border-radius: 0px;
        }
    }
`;

const ButtonWrapper = styled.div`
    position: relative;
`

const AbsoluteStyling = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    top: 40%;
    .btn_arrow {
        background-color: rgba(81, 81, 81, 0.4);
        border-radius: 100%;
        box-shadow: 0 5px 10px 0 rgba(229, 37, 70, 0.1);
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .arrow {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-top: 2px solid rgba(255, 255, 255, 0.95);
        border-right: 2px solid rgba(255, 255, 255, 0.95);
        margin: 0;
      }
    .left {
        transform: rotate(-135deg);
      }
      .right {
        transform: rotate(22deg);
      }
      @media screen and (max-width: 800px) {
        display: none;
    }
`


export { Container, ButtonWrapper, AbsoluteStyling };
