import React from "react";
import { Container } from "./style";

interface BannerProps {
  className?: string;
  children?: React.ReactNode;
}

const Banner = ({ children, className }: BannerProps) => {
  return <Container className={className}>{children}</Container>;
};

export default Banner;
