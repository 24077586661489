export default {
	dummyProfile: require('assets/images/dummy.png'),
	logo: require('assets/images/logo.png'),
	facebook_logo: require('assets/svg/facebook.svg'),
	instagram_logo: require('assets/svg/instagram.svg'),
	twitter_logo: require('assets/svg/twitter.svg'),
	linkedin_logo: require('assets/svg/linkedin.svg'),
	screen_android: require('assets/images/android.png'),
	screen_iphonex: require('assets/images/iphonex.png'),
	icon_google: require('assets/images/icon_google.png'),
	icon_apple: require('assets/images/icon_apple.png'),
	icon_search: require('assets/images/icon_search.png'),
	paket_sayur: require('assets/images/paket_sayur.png'),
	stroberry: require('assets/images/stroberry.png'),
	img_vegetable_right: require('assets/images/img_vegetable_right.png'),
	img_vegetable_left: require('assets/images/img_vegetable_left.png'),
	banner: require('assets/images/banner.png'),
	arrow: require('assets/images/arrow@3x.png'),
	empty_product_banner: require('assets/images/person_with_cart2x.png'),
	pin_location: require('assets/images/iclocation@3x.png'),
};
