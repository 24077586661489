import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "./style";
import BannerTop from "./banner";
import Carousel from "./carousel";
import Product from "./product";
import BannerBottom from "./banner-bottom";
import EmptyProduct from "./empty-product";
import {
  getBannerAction,
  onGetBannerDetailAction,
} from "store/banners/actions";
import {
  onGetProduct,
  onGetProductByBanner,
  onSearchProduct,
} from "store/product/actions";
import { RootState } from "interfaces/common";
import { onScroll, queryToString } from "helpers";
import { Footer } from "components";

const Dashboard = (props: DashboardProps) => {
  const {
    getBannerAction,
    onGetBannerDetailAction,
    onGetProduct,
    onGetProductByBanner,
    banners,
    banner_detail,
    product,
    onSearchProduct,
    product_banner,
    branch_id
  } = props;
  const [bannerId, setBannerId] = useState(0);
  const [isBottom] = onScroll();
  const value: any = queryToString(props.location.search);



  const getBannerBranch = () => {
    let payload = {
      params: {
        branch_id: branch_id //default
      }
    };

    getBannerAction(payload);
  };

  useEffect(() => {
    getBannerBranch();
  }, []);

  const getBannerDetail = (params: string) => {
    setBannerId(parseInt(params));
    onGetBannerDetailAction({
      id: params,
      branch_id: branch_id
    });
  };

  const getProduct = (params: any) => {
    if (params.type === 3) {
      let payload = {
        params: {
          page: params.page ? params.page : null,
          banner_bid: params.banner_id ? params.banner_id : null,
          // banner_bid for production
          category_code: params.category_code ? params.category_code : null,
          product_detail_type: params.product_detail_type
            ? params.product_detail_type
            : null,
          name: params.name && params.name !== "" ? params.name : null,
        },
      };
      onGetProduct(payload);
    } else {
      onGetProductByBanner({
        id: params.banner_id,
        branch_id: branch_id
      });
    }
  };

  return (
    <Container isBottom={ isBottom } className="container">
      <BannerTop />
      {
        banners.data && banners.data.length !== 0 ? (
          <>
            <Carousel
              data={ banners.data }
              getBannerDetail={ getBannerDetail }
              getProduct={ getProduct }
              loading={ banners.loading }
              bannerId={ value.data ? parseInt(value.data) : 0 }
              type={ value.type_banner && parseInt(value.type_banner) }
              branchId={ branch_id }
            />
            <Product
              data={ banner_detail }
              product={ product.list }
              productBanner={ product_banner }
              getProduct={ getProduct }
              bannerId={ bannerId }
              loading={ product.loadingGet }
              bannerLoading={ banners.loading }
              type={ value.type_banner && parseInt(value.type_banner) }
              onSearchProduct={ onSearchProduct }
              branchId={ branch_id }
            />
          </>
        )
          :
          <EmptyProduct data={ banner_detail } />
      }
      <Footer { ...props } />
      { isBottom ? <BannerBottom data={ banner_detail } /> : null }
    </Container>
  );
};

interface EventDispatch {
  getBannerAction: Function;
  onGetBannerDetailAction: Function;
  onGetProduct: Function;
  onGetProductByBanner: Function;
  onSearchProduct: Function;
}

const mapDispatchToProps: EventDispatch = {
  getBannerAction,
  onGetBannerDetailAction,
  onGetProduct,
  onGetProductByBanner,
  onSearchProduct,
};

const mapStateToProps = (state: RootState) => ({
  product: state.product,
  product_banner: state.product.product_banner,
  banners: state.banners.banners,
  banner_detail: state.banner_detail.banner_detail,
  branch: state.branch,
  branch_id: state.branch.branch_id
});

type OwnProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type EnhancedProps = StateProps & DispatchProps;

type DashboardProps = OwnProps & EnhancedProps & RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
